import React, {useRef, useState, useContext} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home';
import CreateUser from './components/createUser';
import AppHome from './components/appHome';
import StartNow from './components/startNow';
import DownloadApp from './components/downloadApp';
import Dashboard from './components/dashboard';
import Login from './components/login';
import PrintQRCode from './components/printQRCode';
import PrintTabs from './components/printTabs';

import { AppContextProvider } from './services/AppContext'
import { VendorUserRef, VendorRef, LoggedUser, TabRef } from './services/data';

function App() {
  const orderID = useRef('');
  const [qrCodeInfo, setQrCodeInfo] = useState(null);
  const newOrder = useRef(true);
  const orderToPlace = useRef(null);
  const locationRef = useRef(null);
  const loggedUser = useRef(LoggedUser)
  const [userNameHeader, setUserNameHeader] = useState("");
  const vendorRef = useRef(VendorRef);
  const tabRef = useRef(TabRef);
  const vendorUserRef = useRef(VendorUserRef);
  const selViewRef = useRef("finance");

  return (
    <AppContextProvider value={{
      loggedUser,
      qrCodeInfo,
      setQrCodeInfo,
      orderID,
      orderToPlace,
      newOrder,
      locationRef,
      userNameHeader,
      setUserNameHeader,
      tabRef,
      vendorRef,
      vendorUserRef,
      selViewRef,
    }}>

      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/createUser" element={<CreateUser />} />
          <Route path="/appHome" element={<AppHome />} />
          <Route path="/startNow" element={<StartNow/>} />
          <Route path="/downloadApp" element={<DownloadApp/>} />
          <Route path="/dashboard" element={<Dashboard/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/printQRCode" element={<PrintQRCode/>} />
          <Route path="/printTabs" element={<PrintTabs/>} />
        </Routes>
      </Router>
    </AppContextProvider>
  );
}

export default App;
