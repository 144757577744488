import React, { useContext, useState, useRef } from 'react';
import AppContext from '../services/AppContext';
import { environment } from '../services/environment';
//import { appStyles, colors } from '../styles/AppStyles';
import Header from './header';
import { generateRandomNumber } from '../services/myFunctions';
import ErrorPage from './errorPage';
import { GetNewID } from '../services/data';
import { useNavigate } from 'react-router-dom';

// const SCREEN_HEIGHT = window.height
// const SCREEN_WIDTH = window.width

const CreateUser = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const { setUserNameHeader } = useContext(AppContext);
  const [errModalVisible, setErrModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  //const [message, setMessage] = useState('');

  const verificationCode = useRef("");

  const onClickSend = () => {
    if (userName == "") {
      setErrorMessage("Digite um nome válido!")
      return;
    }

    verificationCode.current = generateRandomNumber()

    const email = GetNewID();

    const userData = {
      "UserName": userName,
      "UserEmail": email,
      "VerificationCode": verificationCode.current,
      "UserPwd": verificationCode.current
    };

    console.log("Gravando usuário", userData);

    const controller = new AbortController();
    const signal = controller.signal;

    const tOut = setTimeout(() => {
      controller.abort();
      clearTimeout(tOut);
    }, 10000);

    console.log("environment.apiUrl", environment.apiUrl);

    const response = fetch(environment.apiUrl + 'SaveUser',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${environment.preUser}`
        },
        body: JSON.stringify(
          userData
        ),
        signal
      })
      .then((response) => {
        if (!response.ok) {
          // console.log("Error response", response);
          throw new Error('Ops! Erro de comunicação com a nuvem. Por favor, tente mais tarde!');
        }
        return response.json()
      })
      .then((json) => {
        if (json.Saved == "OK") {

          setUserNameHeader(json.userName);

          //save locally
          localStorage.setItem('cliUserEmail', json.UserEmail);

          navigate("/");

        }

      })
      .catch((error) => {
        setErrorMessage(error.message);
        setErrModalVisible(true);
      })
      .finally(() => {
        //console.log('End AdsSearchResult')
      })

  }

  const closeErrModal = () => {
    setErrModalVisible(false);
  }

  const retry = () => {
    setErrModalVisible(false);
    onClickSend()
  }


  return (
    <div className="flex flex-1 flex-col">
      {/* Header component (Assuming you have a Header component) */}
      <Header title="Identificação do usuário" />

      {/* Scrollable area */}
      <div
        
      >
        <div className="flex flex-col items-center">

          {/* Name input area */}
          <div>
            <div className="text-lg font-bold">
              Digite seu primeiro nome
            </div>

            <input
              type="text"
              className="mt-2 h-11 w-40 border border-gray-400 p-2 rounded text-xl font-bold text-gray-800"
              maxLength={10}
              onChange={(e) => { setUserName(e.target.value); setErrorMessage(''); }}
            />

            {/* Error message */}
            <span className="text-red-500">
              {errorMessage}
            </span>

            {/* Send button */}
            <div className="flex flex-1 mt-7 items-center">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={onClickSend}
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Error page modal (Assuming you have an ErrorPage component) */}
      <ErrorPage errorMessage={errorMessage} visible={errModalVisible} closeModal={closeErrModal} retry={retry} />
    </div>
  );

};


export default CreateUser;
