import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import clientAppImg from '../images/clientapp.png'
// import googlePlay from '../images/google-play.png'

// import { IoReorderThreeOutline, IoClose } from 'react-icons/io5';

function Main() {

    const navigate = useNavigate();

    const goToAppHome = () => {
        console.log("goToAppHome");

        navigate('/appHome');
    };

    return (
        <div className="flex justify-center">
            <div className="flex flex-col md:flex-row">
                <div className="flex justify-center">
                    <img src={clientAppImg} alt="ClientAppImage" className="w-[45vh] md:w-[600px]" />
                </div>
                {/* <div className='flex justify-center'>
                    <p className='text-xs font-raleway'>Imagem de mego-studio no Freepik</p>
                </div> */}
                <div className="flex-1 p-4">
                    <div>
                        <p class="px-4 text-3xl md:text-5xl font-bold font-raleway">
                            Aplicativo do cliente
                        </p>
                        <p class="px-4 text-3xl md:text-5xl font-raleway">
                            Autoatendimento
                        </p>

                        <p class="pt-5 px-4 text-lg md:text-xl lg:text-2xl xl:text-2xl font-raleway">
                            Agilize seu atendimento.
                        </p>
                        <p class="px-4 text-lg md:text-xl lg:text-2xl xl:text-2xl font-raleway">
                            Faça seu pedido e acompanhe o andamento
                        </p>
                        <p class="px-4 text-lg md:text-xl lg:text-2xl xl:text-2xl font-raleway">
                            com o aplicativo <span className='font-bold'>AiGarçom Cliente</span>.
                        </p>
                        <p class="px-4 text-lg md:text-xl lg:text-2xl xl:text-2xl font-raleway">
                            diretamente do seu smartphone.
                        </p>
                        <p className='ml-6 mt-6'>
                            <button onClick={()=>goToAppHome()} 
                            className="bg-blue-500 border-blue-500 text-white font-bold py-2 px-2 rounded">
                                Comece agora
                            </button>
                            <a href="https://smartapplications.com.br/apk/aigarcom_c.apk">
                            <button className="ml-1 bg-white-500 border border-blue-500 py-2 px-2 rounded">
                                <div className='flex flex-row'>
                                    <div className='mr-2 text-gray-500 font-bold'>
                                        Baixar App
                                    </div>
                                    {/* <img src={googlePlay} alt="GooglePlay" className="w-[80px]" /> */}
                                </div>
                            </button>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Main;