import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import DisplayQRCode from './displayQRCode';
import { environment } from '../services/environment';
import ErrorPage from './errorPage';
import Spinner from './spinner';

const PrintQRCode = () => {

    const location = useLocation();
    const { vendorID, userToken } = location.state || {}; // Acessando os parâmetros

    const [downloading, setDownloading] = useState(false);
    const [locations, setLocations] = useState([]);

    const [errModalVisible, setErrModalVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const dbCall = () => {
        setDownloading(true);
        getLocations()
    }

    useEffect(() => {
        dbCall();
    }, [vendorID])

    const handlePrint = () => {
        window.print();
    };

    const getLocations = () => {

        const toSearch = { "vendorID": vendorID };

        const controller = new AbortController();
        const signal = controller.signal;

        const tOut = setTimeout(() => {
            controller.abort();
            clearTimeout(tOut);
        }, environment.timeoutValue);

        fetch(environment.centralUrl + 'GetLocations',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
                body: JSON.stringify(
                    toSearch
                ),
                signal
            })
            .then((response) => {
                if (!response.ok) {
                    console.log("Error response ", response);
                    throw new Error('Ops! Erro de comunicação com a nuvem. Por favor, tente mais tarde!');
                }

                return response.json()
            })
            .then((json) => {
                console.log("getLocations response", json);

                setLocations(json);
            })
            .catch((error) => {
                setErrorMessage(error.message);
                setErrModalVisible(true);
            })
            .finally(() => {
                ////console.log('End AdsSearchResult')
                setDownloading(false);
            })
    }


    const closeErrModal = () => {
        setErrModalVisible(false);
    }

    const retry = () => {
        setErrModalVisible(false);
        dbCall()
    }


    return (
        <div>
            <div className="flex print:hidden w-1/2 my-5 justify-center">
                <button
                    onClick={handlePrint}
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                    Imprimir esta página
                </button>
            </div>
            <div className="flex justify-center items-center min-h-screen">
                <div className="w-full max-w-[210mm] bg-white">
                    <div className="grid grid-cols-4 grid-rows-4">
                        {locations.map((item, index) => (
                            <div key={index} className='flex flex-col justify-center  
                                w-50
                                border border-sm border-gray-600
                                print:h-[67mm] print:w-[48mm]
                                print:break-inside-avoid'>
                                <div className='flex justify-center text-sm text-bold'>
                                    {item.vendorName}</div>
                                <div className='flex justify-center text-sm text-bold'>
                                    {item.locationDescr}</div>
                                <div
                                    className="flex 
                                items-center justify-center  
                                print:h-[43mm] print:w-[43mm]"
                                >
                                    <DisplayQRCode text={`https://aigarcom.com.br?v=${item.vendorID}&l=${item.locationID}`} w={160} h={160} />
                                </div>
                                <div key={item} className='flex justify-center text-xs'>Baixe o App em</div>
                                <div key={item} className='flex justify-center text-xs'>www.aigarcom.com.br</div>


                            </div>
                        ))}
                    </div>
                    {/* <div className="print:break-before-page mt-8">
                        <h2 className="text-xl font-semibold">New Page Content</h2>
                        <p>This will appear on a new page when printed.</p>
                    </div> */}
                </div>
                {downloading && <Spinner />}
                <ErrorPage errorMessage={errorMessage} visible={errModalVisible} closeModal={closeErrModal} retry={retry} />
            </div>
        </div>
    );
};

export default PrintQRCode;
