import React, { useContext, useState, useCallback, useRef, useEffect } from 'react';

import { environment } from '../services/environment';
import ErrorPage from './errorPage';
import Spinner from './spinner';
import DisplayQRCode from './displayQRCode';

import { dateStampToDate, decimalToMoney } from '../services/myFunctions';

const VendorBillings = ({ vendorID, userToken }) => {
    const [downloading, setDownloading] = useState(false);
    const [billings, setBillings] = useState([]);

    const [errModalVisible, setErrModalVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const dbCall = () => {
        setDownloading(true);
        getvendorBillings()
    }

    useEffect(() => {
        dbCall();
    }, [vendorID])

    const getvendorBillings = () => {
        const toSearch = { "vendorID": vendorID };

        const controller = new AbortController();
        const signal = controller.signal;

        const tOut = setTimeout(() => {
            controller.abort();
            clearTimeout(tOut);
        }, environment.timeoutValue);

        fetch(environment.centralUrl + 'GetBillings',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
                body: JSON.stringify(
                    toSearch
                ),
                signal
            })
            .then((response) => {
                if (!response.ok) {
                    console.log("Error response ", response);
                    throw new Error('Ops! Erro de comunicação com a nuvem. Por favor, tente mais tarde!');
                }

                return response.json()
            })
            .then((json) => {
                //console.log("getUserVendors response");

                setBillings(json);
            })
            .catch((error) => {
                setErrorMessage(error.message);
                setErrModalVisible(true);
            })
            .finally(() => {
                ////console.log('End AdsSearchResult')
                setDownloading(false);
            })
    }


    const closeErrModal = () => {
        setErrModalVisible(false);
    }

    const retry = () => {
        setErrModalVisible(false);
        getvendorBillings()
    }
    const emptyMessage = () => {
        return (
            <div className="text-center mt-5">
                Nenhum pagamento encontrado para este estabelecimento.
            </div>
        );
    };

    const renderItem = (item) => {
        return (
            <div >
                <div>
                    {/* icon store */}
                </div>
                <div className="p-3 bg-blue-400 rounded">
                    <div>
                        <span className="font-bold text-white text-base " >
                            {item.vendorName}
                        </span>
                    </div>
                    <div className="flex justify-start my-2 h-[1px] bg-white">
                    </div>
                    <div>
                        <span className="text-white text-sm " >
                            Emissão: {dateStampToDate(item.currentPayment)}
                        </span>
                    </div>
                    <div >
                        <span className='text-white text-xs'>
                            De {dateStampToDate(item.fromDate)} Até {dateStampToDate(item.toDate)}
                        </span>
                    </div>
                    <div >
                        <span className='text-white text-xs'>
                            Quantidade de Pedidos: {item.qtdOrders}
                        </span>
                    </div>

                    <div >
                        <span className='font-bold text-white text-lg'>
                            Vencimento: {dateStampToDate(item.dueDate)}
                        </span>
                    </div>
                    <div >
                        <span className="font-bold text-white text-base" >
                            Valor: {decimalToMoney(item.paymentValue)}
                        </span>
                    </div>
                    {!item.paymentDate &&
                        <div className='flex flex-col items-center mt-2'>
                            <h1>Pague com PIX</h1>
                            <DisplayQRCode text={item.pix_chave} />
                        </div>}
                    {item.paymentDate &&
                        <div>
                            <div >
                                <span className="font-bold text-green-300 text-xs" >
                                    Pago
                                </span>
                            </div>
                            <div >
                                <span className="font-bold text-white text-xs" >
                                    Data Pagamento: {dateStampToDate(item.paymentDate)}
                                </span>
                            </div>
                        </div>
                    }

                </div>
            </div>
        );
    };

    return (
        <div >
            <div className="w-full overflow-x-auto p-4">
                <div className="mt-1">
                    <h2 className="text-base font-raleway">
                        Mensalidades</h2>
                </div>

                <div class="flex space-x-4">

                    {billings && billings.length > 0 ? (
                        <div className="flex space-x-4 mt-1">

                            {billings.map(item => (
                                <div key={item.paymentID} className="flex-shrink-0 w-80 h-full">
                                    {renderItem(item)}
                                </div>
                            ))}
                        </div>
                    ) : (
                        emptyMessage()
                    )}

                </div>
            </div>
            {downloading && <Spinner />}
            <ErrorPage errorMessage={errorMessage} visible={errModalVisible} closeModal={closeErrModal} retry={retry} />

        </div>
    );
};

export default VendorBillings;
