import React from 'react';
// import clientAppImg from '../images/clientapp.png'
// import googlePlay from '../images/google-play.png'

import qrCodeDownG from '../images/qrcode-down-g.png'
import qrCodeDownC from '../images/qrcode-down-c.png'

function Downloads() {


    return (
        <div className='m-5 '>
            <h1 className="font-bold font-base font-raleway">Downloads</h1>

            <div>
                <div class="bg-white p-5 ">
                    <div class="flex w-full bg-white p-5  ">
                        <p class="text-base md:text-xl font-bold text-gray-500 mb-2 font-raleway">
                            Baixe o aplicativo <span className='text-xl md:text-2xl'>AiGarçom Bar</span>.
                        </p>
                        <div className='flex w-full'>
                            <img
                                src={qrCodeDownG}
                                alt="qrCodeDownG"
                                className="h-[250px]"
                            />

                            <div className='block md:hidden flex w-full'>
                                <div className='flex '>
                                    <a href="https://smartapplications.com.br/apk/aigarcom_g.apk">
                                        <button
                                            className={`mt-4 border bg-blue-500 text-white font-bold py-2 px-4 rounded`} >
                                            Baixar App
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <p class="text-base md:text-xl font-bold text-gray-500 mb-2 font-raleway">
                            Baixe o aplicativo <span className='text-xl md:text-2xl'>AiGarçom Cliente</span> de autoatendimento.
                        </p>
                        <div className='flex w-full'>
                            <img
                                src={qrCodeDownC}
                                alt="qrCodeDownC"
                                className="h-[250px]"
                            />

                            <div className='block md:hidden flex w-full'>
                                <div className='flex '>
                                    <a href="https://smartapplications.com.br/apk/aigarcom_c.apk">
                                        <button
                                            className={`mt-4 border bg-blue-500 text-white font-bold py-2 px-4 rounded`} >
                                            Baixar App
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>

    );
}

export default Downloads;