import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// import clientAppImg from '../images/clientapp.png'
// import googlePlay from '../images/google-play.png'

import loginImg from '../images/000-login.png'
import qrCodeDownG from '../images/qrcode-down-g.png'

import { IoHomeOutline } from 'react-icons/io5';

function DownloadApp() {

    const navigate = useNavigate();

    const goToHome = () => {
        navigate('/');
    };

    // const goToDashboard = () => {
    //     navigate('/dashboard');
    // };

    return (
        <div>
            {/* Navigation */}
            <nav className="fixed w-full bg-bgTitle">
                <div className="mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between h-16">
                        <div className="flex flex-shrink-0 items-center">
                            <img
                                src="https://smartapplications.com.br/images/logoaigarcom2.png"
                                alt="Logo"
                                className="h-6"
                            />
                        </div>


                        <div className="flex items-center">
                            <button
                                onClick={() => goToHome()}
                                className="cursor-pointer text-gray-700 hover:bg-gray-100 hover:text-gray-900 px-3 py-2 rounded-md"
                            >
                                <div className='flex flex-row items-center'>
                                    <IoHomeOutline className="h-6 w-6" />
                                    <span className='ml-1 md:ml-3 text-xs md:text-base'>Voltar a página principal</span>

                                </div>
                            </button>


                        </div>

                    </div>
                </div>

            </nav>

            <div className="flex justify-center items-center bg-gray-200 h-screen">
                <div className="mt-10 md:w-1/2">
                    <div className='p-2 md:pl-0'>
                        <p class="text-xl md:text-5xl text-gray-800 mb-2 font-raleway">
                            É muito fácil começar
                        </p>
                        <p class="text-base md:text-3xl text-gray-500 mb-2">
                            Experimente por um mês gratuitamente*
                        </p>

                        <header className="flex w-full items-center justify-between">
                            {/* Step 1 */}
                            <div className="flex items-center space-x-3">
                                <div className="flex items-center justify-center 
                                w-10 h-10 bg-gray-400 text-white rounded-full">
                                    1
                                </div>
                                <span className="text-xs md:font-medium text-gray-700">Registre seu estabelecimento</span>
                            </div>

                            {/* Divider */}
                            <div className="mx-4 border-t-2 border-gray-300 w-0 md:w-56"></div>

                            {/* Step 2 */}
                            <div className="flex items-center space-x-3 md:mr-10">
                                <div className="flex items-center justify-center w-10 h-10 
                                bg-bgTitle text-gray-800 rounded-full">
                                    2
                                </div>
                                <span className="text-xs md:font-medium text-gray-700">Baixe o aplicativo</span>
                            </div>
                        </header>


                    </div>
                    <div>
                        <div class="bg-white p-5 ">
                            <div className='flex flex-row'>
                                <div class="flex w-full flex-col bg-white p-5  ">
                                    <p class="text-base md:text-xl font-bold text-gray-500 mb-2 font-raleway">
                                        Baixe o aplicativo <span className='text-xl md:text-2xl'>AiGarçom Bar</span> e comece a usar.
                                    </p>
                                    <div className='flex w-full flex-row'>
                                        <div>
                                            <div className="flex items-center">
                                                <img
                                                    src={loginImg}
                                                    alt="loginImg"
                                                    className="h-[250px] w-[170px]  border border-gray-500"
                                                />
                                            </div>
                                        </div>
                                        <div className='hidden md:block flex  w-full'>
                                            <div className='flex justify-center items-center'>
                                            <img
                                                    src={qrCodeDownG}
                                                    alt="qrCodeDownG"
                                                    className="h-[250px]"
                                                />

                                            </div>
                                        </div>
                                        <div className='block md:hidden flex items-end justify-end w-full'>
                                            <div className='flex '>
                                                <a href="https://smartapplications.com.br/apk/aigarcom_g.apk">
                                                    <button
                                                        className={`mt-4 border bg-blue-500 text-white font-bold py-2 px-4 rounded`} >
                                                        Baixar App
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className='mt-3 mx-4'>
                        <p class="text-gray-700 text-xs font-raleway">
                            <span className='font-bold text-base'>*</span>
                            Nós acreditamos que você vai ficar muito satisfeito com o nosso serviço,
                            mas se ao final do primeiro mês, não quiser continuar com o AiGarçom, basta não realizar o pagamento.
                            O serviço será cancelado automaticamente, sem qualquer ônus.
                        </p>
                        <p class="text-gray-700 text-xs font-raleway">
                        </p>
                        <p class="text-gray-700 text-xs font-raleway">
                        </p>
                    </div>

                </div>

            </div>
        </div>

    );
}

export default DownloadApp;