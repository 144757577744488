import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from '../services/AppContext';
import AppHeader from './appHeader';
// import QRScan from './QRScan';
// import Pulse from './Pulse';
import { environment } from '../services/environment';
import { getLocalDateTime } from '../services/myFunctions';
import ErrorPage from './errorPage';
import { splitQRCodeInfo } from '../services/data';
import { IoStorefrontOutline, IoQrCodeOutline, IoNotificationsOutline, IoCloudOfflineOutline } from 'react-icons/io5';
import QRScan from "./qrScan";

const AppHome = () => {
  const { orderToPlace, newOrder, loggedUser, tabRef, vendorRef, locationRef } = useContext(AppContext);
  const [visible, setVisible] = useState(false);
  const [qrCodeInfo, setQrCodeInfo] = useState(null);
  const [location, setLocation] = useState(null);
  const [btnMsg, setBtnMsg] = useState(null);
  const [errModalVisible, setErrModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const nextAction = useRef("OpenMenu");
  const navigate = useNavigate();

  useEffect(() => {
    if (newOrder.current) {
      setQrCodeInfo(null);
      setLocation(null);
    }
  }, [newOrder]);

  useEffect(() => {
    dbCall();
  }, [qrCodeInfo]);

  useEffect(() => {
    if (location != null) {
      if (nextAction.current === "OpenMenu") {
        onOpenMenu();
      } else {
        navigate("/callWaiter");
      }
    }
  }, [location, navigate]);

  const dbCall = () => {
    if (qrCodeInfo != null) {
      newOrder.current = false;
      getLocation();
    }
  };

  const onTest = () => {
    setQrCodeInfo(splitQRCodeInfo('https://aigarcon.com.br?v=57d33211-115f-46f1-8bab-5a18f24cef8e&l=814bfe1e-1f59-11ef-9cf8-0ad3d9c6262b'));
    setVisible(false);
  };

  const onContinue = () => {
    onOpenMenu();
  };

  const onOpenMenu = () => {
    if (orderToPlace.current == null) {
      registerOrder();
    } else if (vendorRef.current.vendorID !== orderToPlace.current.order.vendorID) {
      alert("Ops! Detectamos que você trocou de estabelecimento, um novo pedido está sendo gerado!");
      registerOrder();
    }

    navigate('/menu');
  };

  const registerOrder = () => {
    const clientName = loggedUser.current.userName.split(" ")[0];
    const order = {
      orderID: "",
      userID: loggedUser.current.userID,
      clientName,
      vendorID: location.vendorID,
      vendorName: location.vendorName,
      locationID: location.locationID,
      locationDescr: location.locationDescr,
      orderDT: getLocalDateTime(),
      statusID: ""
    };

    orderToPlace.current = {
      order,
      totalQuantity: 0,
      totToPay: 0,
      orderItems: []
    };
  };

  // const onOffline = () => {
  //   navigate('/offline');
  // };

  const onCallWaiter = () => {
    nextAction.current = "CallWaiter";
    if (!location) {
      setVisible(true);
    } else {
      navigate("/callWaiter");
    }
  };

  const onStartOrder = () => {
    nextAction.current = "OpenMenu";
    setVisible(true);
  };

  const getLocation = () => {
    const toSearch = {
      userID: loggedUser.current.userID,
      vendorID: qrCodeInfo.vendorID,
      locationID: qrCodeInfo.locationID
    };

    console.log("getLocation", loggedUser);

    fetch(`${environment.apiUrl}getLocation`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loggedUser.current.userToken}`
      },
      body: JSON.stringify(toSearch)
    })
    .then(response => {
      if (!response.ok) throw new Error('Ops! Erro de comunicação com a nuvem. Por favor, tente mais tarde!');
      return response.json();
    })
    .then(json => {
      vendorRef.current = {
        vendorID: json.vendorID,
        vendorName: json.vendorName,
        vendorCPFCNPJ: json.vendorCPFCNPJ
      };
      locationRef.current = json;
      setLocation(json);
    })
    .catch(error => {
      setErrorMessage(error.message);
      setErrModalVisible(true);
    });
  };

  const closeErrModal = () => setErrModalVisible(false);

  const retry = () => {
    setErrModalVisible(false);
    dbCall();
  };

  return (
    <div>
      <AppHeader />
  
      {!visible && (
        <div className="h-16 w-full bg-bgTitle">
          <h1 className="text-2xl font-semibold text-fontLight mt-7 text-center">
            Olá {loggedUser.current.userName}!
          </h1>
        </div>
      )}
  
  
      {!visible && (
        <div className="mt-7 flex flex-col items-center justify-center">
          {vendorRef.current.vendorName !== "" && qrCodeInfo && (
            <div>
              <div className="w-[calc(100vw-40px)] mb-5 rounded-lg bg-highlightButtonVendor">
                <button onClick={() => onContinue()} className="flex flex-row">
                  <div className="flex items-center justify-center">
                    <IoStorefrontOutline className="h-7 w-7"/>
                  </div>
                  <div className="mx-2">
                    <p className="text-white z-0">Toque aqui para continuar seu pedido</p>
                    <p className="text-white z-0">no estabelecimento</p>
                    <p className="text-xl text-white z-0">{vendorRef.current.vendorName}</p>
                  </div>
                  {/* {!visible && !location && <Pulse repeat={true} top={-10} left={140} />} */}
                </button>
              </div>
              <div className="flex items-center mb-5">
                <p className="text-bgButton">ou</p>
              </div>
            </div>
          )}
          <div className="w-[calc(100vw-40px)] rounded-lg bg-transparentButtonVendor">
            <button onClick={() => onStartOrder()} className="flex flex-row">
              <div className="flex items-center justify-center">
                <IoQrCodeOutline className="h-7 w-7" />
              </div>
              <div className="mx-2">
                <p className="text-bgButton z-0">
                  Clique aqui para selecionar {vendorRef.current.vendorName !== "" ? "outro" : "o"} estabelecimento
                </p>
                <p className="text-bgButton z-0">lendo o QRCode mais próximo de você.</p>
              </div>
              {/* {!visible && !location && vendorRef.current.vendorName === "" && (
                <Pulse repeat={true} top={0} left={140} />
              )} */}
            </button>
          </div>
        </div>
      )}
  
      {!qrCodeInfo && (
        <div className="mt-7 h-10 w-full flex items-center justify-center">
          <button onClick={onTest} className="flex flex-row justify-center items-center">
            <IoStorefrontOutline className="h-7 w-7"/>
            <p className="ml-2 text-bgButton underline">Usar loja teste</p>
          </button>
        </div>
      )}
  
      <div className="absolute top-[calc(100vh-220px)] left-[calc(50vw-110px)]">
        <div className="w-52 rounded-lg bg-transparentButtonVendor">
          <button onClick={() => onCallWaiter()} className="flex flex-row items-center">
            <div className="flex items-center justify-center">
              <IoNotificationsOutline className="w-7 h-7" />
            </div>
            <div className="mx-2">
              <p className="text-bgButton z-0">Chamar o garçom</p>
            </div>
          </button>
        </div>
      </div>
  
      <div className="absolute h-10 top-[calc(100vh-150px)] left-2.5">
        <button className="flex flex-row justify-center items-center">
          <IoCloudOfflineOutline className="w-7 h-7" />
          <p className="ml-2 text-bgButton underline">
            Modo offline - ver pedido mesmo sem conexão à internet.
          </p>
        </button>
      </div>
      <ErrorPage errorMessage={errorMessage} visible={errModalVisible} closeModal={closeErrModal} retry={retry} />
  
      {/* <QRScan visible={visible} setVisible={setVisible} setQrCodeInfo={setQrCodeInfo} /> */}
      <QRScan/>
    </div>
  );
  };

export default AppHome;

