import React, { useContext, useState, useCallback, useRef, useEffect } from 'react';

import AppContext from '../services/AppContext';
import { environment } from '../services/environment';
import ErrorPage from './errorPage';
import Spinner from './spinner';
import { timeStampToDate, calcDueDate } from '../services/myFunctions';

const UserVendorsBills = ({ userID, onVendorSelected, selectedVendorID }) => {
    const [downloading, setDownloading] = useState(false);
    const [userVendors, setUserVendors] = useState([]);

    const [errModalVisible, setErrModalVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const dbCall = () => {
        setDownloading(true);
        getUserVendors()
    }

    useEffect(() => {
        dbCall();
    }, [])


    const getUserVendors = () => {
        //console.log("getOrders")
        const toSearch = { "userID": userID };

        //console.log("toSearch ", toSearch);

        const controller = new AbortController();
        const signal = controller.signal;

        const tOut = setTimeout(() => {
            controller.abort();
            clearTimeout(tOut);
        }, environment.timeoutValue);

        fetch(environment.centralUrl + 'getUserVendorsBills',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${environment.preUser}`
                },
                body: JSON.stringify(
                    toSearch
                ),
                signal
            })
            .then((response) => {
                if (!response.ok) {
                    console.log("Error response ", response);
                    throw new Error('Ops! Erro de comunicação com a nuvem. Por favor, tente mais tarde!');
                }

                return response.json()
            })
            .then((json) => {
                //console.log("getUserVendors response");

                setUserVendors(json);
            })
            .catch((error) => {
                setErrorMessage(error.message);
                setErrModalVisible(true);
            })
            .finally(() => {
                ////console.log('End AdsSearchResult')
                setDownloading(false);
            })
    }


    const closeErrModal = () => {
        setErrModalVisible(false);
    }

    const retry = () => {
        setErrModalVisible(false);
        getUserVendors()
    }

    const emptyMessage = () => {
        return (
            <div className="text-center mt-5">
                Este usuário não está ativo em nenhum estabelecimento!
            </div>
        );
    };
    const renderItem = (item) => {
        return (
            <button onClick={() => onVendorSelected(item)}
                className="w-full mt-2">
                <div>
                    {/* icon store */}
                </div>

                <div className={`flex flex-col p-3 ${item.vendorID === selectedVendorID?"bg-blue-400":"bg-gray-300"} rounded`}>
                    <div className="flex justify-start ">
                        <span className="font-bold text-white text-sm font-raleway " >
                            {item.vendorName}
                        </span>
                    </div>
                    <div className="flex justify-start ">
                        <span className='text-white text-xs font-raleway'>
                            {item.vendorStreet}{item.vendorStreet === null ? "" : ","} {item.vendorStreetNumber}
                        </span>
                    </div>
                    <div className="flex justify-start ">
                        <span className='text-white text-xs font-raleway'>
                            {item.vendorDistrict} {item.vendorCity}
                        </span>
                    </div>
                    {/* <div className="flex justify-start">
                        <span className="font-bold text-white text-xs font-raleway" >
                            Função : {item.roleDescription}
                        </span>
                    </div> */}
                    <div className="flex justify-start my-2 h-[1px] bg-white">
                    </div>
                    <div className="flex justify-start">
                        <span className="text-white text-base font-bold font-raleway" >
                            Próxima Mensalidade
                        </span>
                    </div>

                    <div className="flex justify-start">
                        <span className="text-white text-xs font-raleway" >
                            Valor estimado : {item.price}
                        </span>
                    </div>

                    <div className="flex justify-start">
                        <span className="text-white text-xs font-raleway" >
                            Vencimento : {timeStampToDate(calcDueDate(item.nextPayment, 5))}
                        </span>
                    </div>

                    <div className="flex justify-start">
                        <span className="text-white text-xs font-raleway" >
                            Referente ao período de {timeStampToDate(item.currentPayment)} até {timeStampToDate(item.nextPayment)}
                        </span>
                    </div>
                    <div className="flex justify-start">
                        <span className="text-white text-xs font-raleway" >
                            Quantidade estimada de pedidos para o período: {item.qtdOrders}
                        </span>
                    </div>
                </div>
            </button>
        );
    };

    return (
        <div className="flex min-h-screen w-full mt-5">

            <div className="flex flex-col w-full h-full">

                <div>
                    <h2 className="text-base font-raleway">
                        Selecione o estabelecimento</h2>
                </div>

                <div className="mt-0">
                    {userVendors && userVendors.length > 0 ? (
                        <div>
                            {userVendors.map(item => (
                                <div key={item.vendorID}>
                                    {renderItem(item)}
                                </div>
                            ))}
                        </div>
                    ) : (
                        emptyMessage()
                    )}
                </div>
            </div>

            {downloading && <Spinner />}
            <ErrorPage errorMessage={errorMessage} visible={errModalVisible} closeModal={closeErrModal} retry={retry} />

        </div>
    );
};

export default UserVendorsBills;
