// const environment = {
//   'apiUrl':'http://192.168.0.201:21040/api/',
//   'socketIoUrl' : "http://192.168.0.201:21040",
//   'preUser' : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJWYWxpZGF0ZWQiOiJPSyIsIlVzZXJJRCI6IjMyODZlYWUzLWM2ZTMtNGZjZS1iNjFlLTUwYTBhODk1OGYyOSIsIlVzZXJOYW1lIjoicHJldXNlciIsIlVzZXJFbWFpbCI6InByZXVzZXJAc21hcnRhcHBsaWNhdGlvbnMuY29tIiwiVXNlclJvbGUiOiJQcmVVc2VyIiwiSXNFbWFpbFZlcmlmaWVkIjoiMCIsImlhdCI6MTcwMTY5ODUzNiwiZXhwIjoxNzMzMjM0NTM2fQ.77K7yN7MwuSSK-zdYZjsehHhzdXNmgxjN8w31mAYsYg',
//   'emailKey' : '3caf9b272f64cd44501ac226e4fbcedc'
// }

const environment = {
  'apiUrl':'http://192.168.0.205:21040/api/',
  'centralUrl':'http://192.168.0.205:21040/api/',
  'socketIoUrl' : "http://192.168.0.205:21040",
  'preUser' : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJWYWxpZGF0ZWQiOiJPSyIsIlVzZXJJRCI6IjMyODZlYWUzLWM2ZTMtNGZjZS1iNjFlLTUwYTBhODk1OGYyOSIsIlVzZXJOYW1lIjoicHJldXNlciIsIlVzZXJFbWFpbCI6InByZXVzZXJAc21hcnRhcHBsaWNhdGlvbnMuY29tIiwiVXNlclJvbGUiOiJQcmVVc2VyIiwiSXNFbWFpbFZlcmlmaWVkIjoiMCIsImlhdCI6MTcwMTY5ODUzNiwiZXhwIjoxNzMzMjM0NTM2fQ.77K7yN7MwuSSK-zdYZjsehHhzdXNmgxjN8w31mAYsYg',
  'emailKey' : '3caf9b272f64cd44501ac226e4fbcedc',
  'timeoutValue': 20000
}

// const environment = {
//   'apiUrl':'http://localhost:21040/api/',
//   'socketIoUrl' : "http://localhost:21040",
//   'preUser' : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJWYWxpZGF0ZWQiOiJPSyIsIlVzZXJJRCI6IjMyODZlYWUzLWM2ZTMtNGZjZS1iNjFlLTUwYTBhODk1OGYyOSIsIlVzZXJOYW1lIjoicHJldXNlciIsIlVzZXJFbWFpbCI6InByZXVzZXJAc21hcnRhcHBsaWNhdGlvbnMuY29tIiwiVXNlclJvbGUiOiJQcmVVc2VyIiwiSXNFbWFpbFZlcmlmaWVkIjoiMCIsImlhdCI6MTcwMTY5ODUzNiwiZXhwIjoxNzMzMjM0NTM2fQ.77K7yN7MwuSSK-zdYZjsehHhzdXNmgxjN8w31mAYsYg',
//   'emailKey' : '3caf9b272f64cd44501ac226e4fbcedc'
// }

// const environment = {
//   'apiUrl':'https://www.smartapplications.com.br/api/',
//   'socketIoUrl' : "http://www.smartapplications.com.br:21020",
//   'preUser' : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJWYWxpZGF0ZWQiOiJPSyIsIlVzZXJJRCI6IjMyODZlYWUzLWM2ZTMtNGZjZS1iNjFlLTUwYTBhODk1OGYyOSIsIlVzZXJOYW1lIjoicHJldXNlciIsIlVzZXJFbWFpbCI6InByZXVzZXJAc21hcnRhcHBsaWNhdGlvbnMuY29tIiwiVXNlclJvbGUiOiJQcmVVc2VyIiwiSXNFbWFpbFZlcmlmaWVkIjoiMCIsImlhdCI6MTcwMTY5ODUzNiwiZXhwIjoxNzMzMjM0NTM2fQ.77K7yN7MwuSSK-zdYZjsehHhzdXNmgxjN8w31mAYsYg',
//   'emailKey' : '3caf9b272f64cd44501ac226e4fbcedc'
// }

export {environment};