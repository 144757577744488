
import { Html5Qrcode } from "html5-qrcode";
import React, { useState, useRef, useEffect } from 'react';

const QRScan = () => {
  const [isScanning, setIsScanning] = useState(false);
  const [qrCodeScanner, setQrCodeScanner] = useState(null);
  const [qrCode, setQrCode] = useState(null);
  const [isScanned, setIsScanned] = useState(false);
  const timerRef = useRef(null);


  useEffect(()=>{
    if ((!isScanned) && qrCode !== null){
        setIsScanned(true);
        
        console.log("setTimer");
        stopCamera()

        // timerRef.current = setInterval(() => {
        //     console.log("timer");
        //     clearInterval(timerRef.current);
        //     stopCamera()
        // }, 5000);
    
    }
  }
  ,[qrCode])
  const startCamera = () => {
    if (!isScanning) {
      const html5QrCode = new Html5Qrcode("reader");

      // Configure the scanner to start scanning
      html5QrCode.start(
        { facingMode: "environment" }, // Start with rear camera
        { fps: 10, qrbox: 250 },
        onScanSuccess,
        onScanError
      ).then(() => {
        setQrCodeScanner(html5QrCode);
        setIsScanning(true);
      }).catch(err => {
        console.error("Error starting the camera: ", err);
      });
    }
  };

  const stopCamera = () => {
    console.log("Called stopCamera", isScanning);

    if (isScanning && qrCodeScanner) {
      qrCodeScanner.stop().then(() => {
        console.log("Camera stopped");

        // Clear the scanner after stopping it
        qrCodeScanner.clear();
        console.log("Scanner cleared");

        setIsScanning(false);
      }).catch(err => {
        console.error("Error stopping the camera: ", err);
      });
    }
  };

  // Called when QR code is successfully scanned
  const onScanSuccess = (decodedText, decodedResult) => {
    
    setQrCode(decodedText);

  };

  // Called when there is an error during scan
  const onScanError = (error) => {
    //console.error(`Scan error: ${error}`);
  };

  return (
    <div>
      <div className="w-1/4 h-1/4">{qrCode}</div>
      <div id="reader" style={{ width: "500px", height: "400px" }}></div>
      <button onClick={startCamera} disabled={isScanning}>Start Camera</button>
      <button onClick={stopCamera} disabled={!isScanning}>Stop Camera</button>
    </div>
  );
};

export default QRScan;
